import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { eventCallbackTriggers } from "../../../templates/wppage";
import "./donateBox.css";

const DonationComp = ({ config, defaultAmounts }) => {
  const [amount, setAmount] = useState(config?.initialAmount);
  const [customAmount, setCustomAmount] = useState("");
  const [err, setError] = useState("");

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("dollar-a-day-usa", event),
    []
  );
  const pageContent = () => {
    let donationAmount = 0;

    if (amount || (customAmount && customAmount > 0)) {
      donationAmount = amount || customAmount;
    }

    return (
      `<gatsby_donation amount="${donationAmount}" donationid="${
        config?.donationId
      }" btntext="Donate ${
        donationAmount ? `- ${config?.symbol}${donationAmount}` : ""
      } Monthly"` +
      'projectid="" btnbg="rgb(88 153 208)" colortext="black" colorprimary="#5899D0"' +
      'colorsecondary="#5899D0" colorbackground="white" colormuted="#f6f6f6"' +
      `colorhighlight="#efeffe" titletext="${config?.titletext}" isrecurring="true" removeAccountType="true" />`
    );
  };
  const PageContent = React.memo(PageContentNonMemoized);

  return (
    <div className="donation-box-wrapper">
      <div className="donation-heading-wrapper">
        <h4 className="text-center donation-box-heading">
          <b style={{ color: "#1f4b72" }}>Small Contribution -</b>{" "}
          <b style={{ color: "rgb(88 153 208)" }}> Big Transformation</b>
        </h4>
      </div>
      <div className="d-flex justify-content-end align-items-center mt-2">
        <p
          className="currency"
          style={{
            color: "white",
            fontWeight: "bold",
            marginBottom: "0",
            fontSize: "14px",
          }}
        >
          {config?.symbol} {config?.currency}
        </p>
      </div>
      {defaultAmounts &&
        defaultAmounts?.map((a) => (
          <div className="mb-3">
            <div style={{ color: "#1f4b72" }}>
              <b>
                {config?.symbol}
                {a?.perday} per Day
              </b>
              <p style={{ marginBottom: "0" }}>
                {config?.symbol}
                {a?.value} donation every month
              </p>
            </div>

            <label
              htmlFor={a.value}
              className="radio-container d-flex justify-content-between align-items-center my-1"
            >
              <div>
                <p style={{ marginBottom: "0", fontSize: "20px" }}>
                  <b>
                    {config?.symbol}
                    {a.value}
                  </b>
                </p>
                <span>Frequency-Monthly</span>
              </div>
              <input
                type="radio"
                id={a.value}
                name="radio-group"
                value={a.value}
                checked={amount === a?.value}
                className="radio"
                onClick={() => {
                  setAmount(a.value);
                  setCustomAmount("");
                  setError("");
                }}
              />
            </label>
          </div>
        ))}
      <div style={{ color: "#1f4b72" }}>
        <b>Custom</b>
        <input
          type="number"
          inputMode="numeric"
          value={customAmount}
          placeholder={`${config?.symbol}Custom Amount`}
          className="my-2 custom-input"
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === "+") {
              event.preventDefault();
            }
          }}
          onKeyPress={(event) => {
            const invalidChars = ["-", "+", "e", "E"];
            if (invalidChars.includes(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(event) => {
            const pattern = /^[1-9]\d{0,9}$/;
            if (!pattern.test(event?.target?.value)) {
              if (!event?.target?.value || event?.target?.value.length > 10) {
                setAmount(config?.initialAmount);
                setError("Amount must be less than or equal to 1000000000");
                setCustomAmount("");
              } else {
                setAmount(config?.initialAmount);
                setError("Please enter a valid amount");
              }
            } else if (
              event?.target?.value &&
              event?.target?.value.length === 1 &&
              config?.currency === "INR"
            ) {
              setCustomAmount(event?.target?.value);
              setAmount(config?.initialAmount);
              setError("Amount must be more than ₹10");
            } else {
              setAmount("");
              setError("");
              setCustomAmount(event?.target?.value);
            }
          }}
        />
        {err && <p style={{ color: "red", fontSize: "14px" }}>{err}</p>}
      </div>
      <div>
        <PageContent
          eventCallback={eventCallback}
          pageContent={pageContent()}
        />
      </div>
    </div>
  );
};
DonationComp.propTypes = {
  config: PropTypes.shape({
    initialAmount: PropTypes.number,
    donationId: PropTypes.number,
    symbol: PropTypes.string,
    currency: PropTypes.string,
    titletext: PropTypes.string,
  }).isRequired,
  defaultAmounts: PropTypes.arrayOf(
    PropTypes.shape({
      perday: PropTypes.number,
      value: PropTypes.number,
    }).isRequired
  ).isRequired,
};

export default DonationComp;
