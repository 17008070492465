import React from "react";
import PropTypes from "prop-types";
import "./ContentHeader.css";

const ContentHeader = ({ banner, title, subTitle }) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(36, 73, 105, 0.5), rgba(36, 73, 105, 0.5)),url(${banner})`,
      }}
      className="bannerWrapper"
    >
      <div className="textWrapper">
        <h1 className="headerTitle">
          <b>{title}</b>
        </h1>
        <h3 className="headerSubtitle">{subTitle}</h3>
      </div>
    </div>
  );
};
ContentHeader.propTypes = {
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
export default ContentHeader;
