import React from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import PropTypes from "prop-types";
import "./slider.css";

const Slider = ({ heading, highlightText, impactStories }) => {
  const breakPoints = [
    { width: 300, itemsToShow: 1, pagination: true },
    { width: 550, itemsToShow: 2, pagination: true },
    { width: 600, itemsToShow: 2, pagination: true },
    { width: 800, itemsToShow: 3, pagination: true },
  ];
  return (
    <div className="container-fluid">
      <div
        className="my-5 py-5 slider-content-wrapper"
        sx={{
          alignCarouselItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="mx-2">
          <h2 className="pb-5 sliders-heading">
            {heading} <span className="highlightText">{highlightText}</span>
          </h2>
          <div>
            <ReactElasticCarousel breakPoints={breakPoints}>
              {impactStories.map((value) => (
                <div key={value.id} className="stories-slide">
                  <div style={{ position: "relative" }}>
                    {value?.image && (
                      <a
                        href={value?.link}
                        target="_blank"
                        className="links"
                        rel="noreferrer"
                      >
                        <img
                          src={value?.image}
                          className="mb-2 storiesImg"
                          alt="stories"
                        />
                      </a>
                    )}
                    {value?.text && (
                      <div
                        style={{
                          backgroundColor: value?.color,
                        }}
                        className="text"
                      >
                        <span>{value?.text}</span>
                      </div>
                    )}
                  </div>
                  {value.title && (
                    <h6 className="my-2 title px-3">
                      <b>{value.title}</b>
                    </h6>
                  )}
                  <div className="slider-desc">
                    {value.description && (
                      <p className="desc  px-3" style={{ fontSize: "16px" }}>
                        {value.description}
                      </p>
                    )}
                  </div>
                  <div className="my-3">
                    {value?.additionalInfo &&
                      value?.additionalInfo?.map((i) => (
                        <div className="row info-wrapper my-2">
                          <div className="col-4 info-image-wrapper">
                            <div className={`icon ${i?.iconClass}`} />
                          </div>
                          <div className="col-8 info-content">
                            <h5 className="info-title">{i?.title}</h5>
                            <p className="info-desc">{i?.desc}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </ReactElasticCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};
Slider.propTypes = {
  heading: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  impactStories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      additionalInfo: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
          desc: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};
export default Slider;
