import React from "react";
import PropTypes from "prop-types";
import savings from "../../../../static/imgs/savings.png";
import "./oneTimeDonation.css";

const OneTimeDonation = ({ text }) => {
  return (
    <section className="container">
      <div className="row d-flex oneTimeDonationWrapper">
        <div className="col-12 col-md-6 text-white text-column">
          <p className="oneTimeDonationText">{text}</p>
          <a
            href="https://donations.heartfulness.org/"
            target="_blank"
            className="clickHere"
            rel="noreferrer"
          >
            Click here
          </a>
        </div>
        <div className="col-12 col-md-6 savings">
          <img
            id="image"
            src={savings}
            alt="savings"
            className="savingsImage"
          />
        </div>
      </div>
    </section>
  );
};
OneTimeDonation.propTypes = {
  text: PropTypes.string.isRequired,
};
export default OneTimeDonation;
