import React, { useState } from "react";
import PropTypes from "prop-types";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./testimonials.css";

const Testimonials = ({ slides, noImage = false }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  return (
    <section className="container">
      <div className="my-5 py-5 testimonialsWrapper">
        <div className="row d-flex justify-content-center">
          {!noImage && <div className="col-md-1 empty-column" />}
          <div className="col-11 col-md-10">
            <div className="content-bar d-flex align-items-center">
              {slides?.[currentSlide]?.image && (
                <div className="image-container">
                  <img
                    id="image"
                    src={slides?.[currentSlide]?.image}
                    alt="Person"
                    className="img-fluid testimonialImage"
                  />
                </div>
              )}
              <div
                className={`description-container ${
                  !noImage ? "imageDesc" : "noImageDesc"
                } d-flex flex-grow-1 align-items-center`}
              >
                <div className="py-2">
                  <h1
                    className={
                      slides?.[currentSlide]?.image
                        ? "testimonialHeading testimonialHeadingMargin"
                        : "testimonialHeading"
                    }
                  >
                    Testimonials
                  </h1>
                  <p
                    id="description"
                    className="text-white mt-3 testimonial-desc"
                  >
                    “{slides?.[currentSlide]?.description}”
                  </p>
                  <h5 id="name" className="text-white mt-3 testimonial-name">
                    {slides?.[currentSlide]?.name}
                  </h5>
                </div>
                <div className="arrow-container">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size="2x"
                    className={`m-2 ${currentSlide === 0 ? "arrow-btn" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => prevSlide()}
                    disabled={currentSlide === 0}
                  />
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ cursor: "pointer" }}
                    size="2x"
                    className={`m-2 ${
                      currentSlide === slides.length - 1 ? "arrow-btn" : ""
                    }`}
                    onClick={() => nextSlide()}
                    disabled={currentSlide === slides.length - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
Testimonials.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  noImage: PropTypes.bool,
};
Testimonials.defaultProps = {
  noImage: false,
};
export default Testimonials;
